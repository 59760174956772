import Vue from 'vue'
import PackageFeatures from '@/common/enums/packagesFeatureTypeEnum'
import ApplicationType from '@/common/enums/applicationTypeEnum'
import AccountTypes from '@/common/enums/accountTypeEnum'
import Packages from '@/common/enums/packagesEnum'

export default {
  namespaced: true,
  state: {
    currentUser: {},
    appInitialized: false,
    token: null,
    availablePackages: {},
    userGroups: [],
    userName: '',
    selectedGroupId: null,
    createGroupData: {},
    isOtpSend: false,
  },
  mutations: {
    SET_USER(state, payload) {
      state.currentUser = payload
    },
    SET_PACKAGES_AVAILABLE(state, payload) {
      const updatedPackages = payload.map(pack => {
        const updatedPack = { ...pack }
        const updatedPlanFeatures = []
        const planHasFeature = pack.plan_has_feature
        planHasFeature.forEach(plan => {
          const { feature } = plan
          const { price } = plan
          feature.isDisabled = [
            PackageFeatures.DEVICES,
            PackageFeatures.PROFILE,
            PackageFeatures.MEDIA,
          ].includes(feature.slug)
          feature.isSelected = true
          feature.price = price
          updatedPlanFeatures.push(plan)
        })
        if (pack.slug === Packages.ONE_SCREEN) {
          updatedPack.screenQuota = 1
        } else if (pack.slug === Packages.TWO_SCREENS) {
          updatedPack.screenQuota = 2
        } else {
          updatedPack.screenQuota = 3
        }
        return { ...updatedPack, plan_has_feature: updatedPlanFeatures }
      })
      state.availablePackages = updatedPackages
    },

    SET_USER_GROUPS(state, payload) {
      state.userGroups = []
      state.userGroups = payload
    },
    SET_USER_NAME(state, payload) {
      state.userName = payload.nickname
    },
    SET_APP_INITIALIZED(state) {
      state.appInitialized = true
    },
    SET_IS_OTP_SEND(state, payload) {
      state.isOtpSend = payload
    },
    SET_SELECTED_GROUP_ID(state, id) {
      state.selectedGroupId = id
    },
    SET_CREATE_GROUP_DATA(state, groupData) {
      state.createGroupData = groupData
    },
    CLEAR_STORE(state) {
      state.currentUser = {}
      state.token = null
      state.availablePackages = {}
      state.userGroups = []
      state.userName = ''
      state.selectedGroupId = null
      state.createGroupData = {}
    },
  },
  actions: {
    setUser: ({ commit }, user) => {
      commit('SET_USER', user)
    },
    setUserName: ({ commit }, username) => {
      commit('SET_USER_NAME', username)
    },
    async getUserIfNotExist({ commit, state }, forceFetch = false) {
      if (forceFetch || !state.currentUser.id) {
        try {
          const {
            data: { data },
          } = await Vue.prototype.$axios.get('user')
          commit('SET_USER', data)
        } catch ({
          response: {
            data: { statusCode, message },
          },
        }) {
          if (message === 'User not found') {
            try {
              const {
                data: { data },
              } = await Vue.prototype.$axios.post('user', {
                name: Vue.prototype.$auth.user.nickname,
              })
              commit('SET_USER', data)
            } catch (e) {
              throw new Error('Error in creating user')
            }
          }
        }
      }
    },
    async setPackagesAvailable({ commit }) {
      this.getPackagesLoader = true
      try {
        const {
          data: { data },
        } = await Vue.prototype.$axios.get('/user-has-group/get-all-plans')

        commit('SET_PACKAGES_AVAILABLE', data)
      } catch (e) {
        throw new Error('Error in fetching packages')
      }
    },
    async setAllUserGroupIfNotPresent({ commit, state }, forceFetch = false) {
      if (forceFetch || !state.userGroups.length) {
        try {
          const {
            data: { data },
          } = await Vue.prototype.$axios.get('user-has-group/get-user-groups')
          commit('SET_USER_GROUPS', data)
        } catch ({
          response: {
            data: { statusCode, message },
          },
        }) {
          commit('SET_USER_GROUPS', [])
          Vue.prototype.$swal(message.toString())
        }
      }
    },
    async setAllUserGroupAfterUnSubscribed({ commit }) {
      try {
        const {
          data: { data },
        } = await Vue.prototype.$axios.get('user-has-group/get-user-groups')
        commit('SET_USER_GROUPS', data)
      } catch ({
        response: {
          data: { statusCode, message },
        },
      }) {
        commit('SET_USER_GROUPS', [])
        Vue.prototype.$swal(message.toString())
      }
    },
    logoutUserFromOtherApp() {
      try {
        window.open(
          `${process.env.VUE_APP_SIMI_LAUNCHER_BASE_URL}?logout=true`,
          'connectLauncher',
        )
        window.open(
          `${process.env.VUE_APP_SIMI_IOT_BASE_URL}?logout=true`,
          'connectIOT',
        )
      } catch (e) {
        // console.log(e)
      }
    },
    async logoutUserWithRedirect() {
      await Vue.prototype.$auth.logout({
        returnTo: `${window.location.origin}/logout`,
      })
    },
    async logoutUserLocalOnly({ commit }) {
      await Vue.prototype.$auth.logout({ localOnly: true })
      commit('CLEAR_STORE')
    },
  },
  getters: {
    getUser: state => state.currentUser,
    getAppInitialized: state => state.appInitialized,
    getAllPackagesAvailable: state => state.availablePackages,
    getGroups: state => state.userGroups,
    getSelectedGroupId: state => state.selectedGroupId,
    getSelectedGroupMember(state) {
      if (state.userGroups && state.userGroups.length) {
        const group = state.userGroups.find(
          object => object.user_has_group.id === state.selectedGroupId,
        )
        if (group) {
          return group
        }
        return null
      }
      return null
    },
    getSelectedGroupMemberStatus(state, getters) {
      return getters.getSelectedGroupMember.user_has_group
        && getters.getSelectedGroupMember.user_has_group.package
        && getters.getSelectedGroupMember.user_has_group.package.isActive
        ? getters.getSelectedGroupMember.user_has_group.package.isActive
        : false
    },
    getUserOwnCreatedGroup(state) {
      if (state.currentUser) {
        return state.currentUser.user_has_group
      }
      return []
    },
    getUserOwnCreatedGroupIds(state) {
      if (state.currentUser) {
        return state.currentUser.user_has_group.map(obj => obj.id)
      }
      return []
    },
    getCurrentUserEmail(state) {
      if (state.currentUser && state.currentUser.email) {
        return state.currentUser.email
      }
      return null
    },
    getCurrentUserStripePaymentCardNumber(state) {
      if (
        state.currentUser
        && state.currentUser.stripe_customer_card
        && state.currentUser.stripe_customer_card[0]
        && state.currentUser.stripe_customer_card[0].card_object
        && state.currentUser.stripe_customer_card[0].card_object.last4
      ) {
        return state.currentUser.stripe_customer_card[0].card_object.last4
      }
      return null
    },
    getCurrentUserStripePaymentCardBrandType(state) {
      if (
        state.currentUser
        && state.currentUser.stripe_customer_card
        && state.currentUser.stripe_customer_card[0]
        && state.currentUser.stripe_customer_card[0].card_object
        && state.currentUser.stripe_customer_card[0].card_object.brand
      ) {
        return state.currentUser.stripe_customer_card[0].card_object.brand
      }
      return null
    },
    // getMemberRoleInSelectedGroup: state => payload => {
    //   state.userGroups.find(group => group.id === JSON.parse(payload))
    // },
    getSelectedGroupAvailableApplications(state, getters) {
      let isSimiLauncher = false
      let isSimiIot = false
      let isSimiPowerSaver = false
      if (
        getters.getSelectedGroupMember
        && getters.getSelectedGroupMember.permissions
      ) {
        // eslint-disable-next-line no-restricted-syntax
        for (const permissions of getters.getSelectedGroupMember.permissions) {
          if (
            permissions.feature
            && permissions.feature.application_type
              === ApplicationType.SIMI_LAUNCHER
          ) {
            isSimiLauncher = true
          }
          if (
            permissions.feature
            && permissions.feature.application_type === ApplicationType.SIMI_IOT
          ) {
            isSimiIot = true
          }
          if (
            permissions.feature
            && permissions.feature.application_type === ApplicationType.SIMI_POWER_SAVER
          ) {
            isSimiPowerSaver = true
          }
        }
      }
      return { isSimiLauncher, isSimiIot, isSimiPowerSaver }
    },
    getCreateGroupData: state => state.createGroupData,
    isUserAdmin(state) {
      let isAdmin = false
      if (
        state.currentUser
        && state.currentUser.user_has_role
        && state.currentUser.user_has_role.length
      ) {
        // eslint-disable-next-line no-restricted-syntax
        for (const userHasRoleElement of state.currentUser.user_has_role) {
          if (
            userHasRoleElement.role
            && userHasRoleElement.role.role === AccountTypes.ADMIN
          ) {
            isAdmin = true
            break
          }
        }
      }
      return isAdmin
    },
    isSimmiUser(state) {
      let isSimmiUser = false
      if (
        state.currentUser
        && state.currentUser.user_has_role
        && state.currentUser.user_has_role.length
      ) {
        // eslint-disable-next-line no-restricted-syntax
        for (const userHasRoleElement of state.currentUser.user_has_role) {
          if (
            userHasRoleElement.role
            && userHasRoleElement.role.role === AccountTypes.USER
          ) {
            isSimmiUser = true
            break
          }
        }
      }
      return isSimmiUser
    },
    isOtpSend(state) {
      return state.isOtpSend
    },
  },
}
